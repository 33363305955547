import React from 'react';
import './FirstScreen.css';
import logo from '../../images/logo-main.svg';

function FirstScreen() {
    return(
        <section className='first-screen'>
            <div className='first-screen__container'>
                <article className='first-screen__information-box'>
                    <img className='first-screen__logo' src={logo} alt='Логотип'/>
                    <h1 className='first-screen__title'>Добро пожаловать в МЕДИАКЛАСС ИИ</h1>
                    <p className='first-screen__description'>Сервис позволит вам получать текстовый результат распознавания медиафайлов</p>
                    <a className='first-screen__link' href="#download-screen">
                        <p className='first-screen__text'>Скачать дистрибутив</p>
                    </a>
                </article>
            </div>
        </section>
    )
}

export default FirstScreen