// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./vendor/normalize.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  font-family: 'Fira Sans', sans-serif;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color: #F9FAFB;
  color: #111827;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAGA;EACE,oCAAoC;EACpC,uBAAuB;AACzB;;AAEA;EACE,SAAS;EACT,yBAAyB;EACzB,cAAc;EACd,mCAAmC;EACnC,kCAAkC;AACpC","sourcesContent":["@import url(./vendor/normalize.css);\n@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500&display=swap');\n\nhtml {\n  font-family: 'Fira Sans', sans-serif;\n  scroll-behavior: smooth;\n}\n\nbody {\n  margin: 0;\n  background-color: #F9FAFB;\n  color: #111827;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
