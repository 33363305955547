export const items = [
    {
      id: 0,
      description: `Нажмите кнопку на данном сайте для загрузки программы в виде архива.`
    },
    {
      id: 1,
      description: `Распакуйте содержимое архива в любую удобную директорию, используя пароль "123321vr". `
    },
    {
      id: 2,
      description: `После успешного извлечения файлов выполните запуск файла "ffmpeg install.bat" с правами администратора. Для этого щелкните правой кнопкой мыши по файлу и выберите "Запуск от имени администратора". Отображение сообщения "Complete" свидетельствует об успешном завершении установки необходимого пакета. В случае отсутствия данного сообщения сделайте снимок экрана с возможной ошибкой и обратитесь в службу поддержки. Обратите внимание, что для выполнения этого шага требуется подключение к интернету.`
    },
    {
      id: 3,
      description: `Создайте ярлык программы "МЕДИАКЛАСС ИИ.exe" на рабочем столе, чтобы обеспечить удобный запуск. Для этого выполните следующие шаги: щелкните правой кнопкой мыши по файлу, выберите "Показать дополнительные параметры", затем "Отправить" и выберите опцию "На рабочий стол (создать ярлык)"`
    }
  ];
