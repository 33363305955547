import React from "react"
import './ItemInstruction.css';

function ItemInstruction(props) {

    const { description } = props.item;

    return(
        <>
            <li className="download-screen__instructions-item">{description}</li>
        </>
    )
}

export default ItemInstruction