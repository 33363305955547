import React, { useEffect, useState } from 'react';
import './App.css';
import Header from '../Header/Header';
import FirstScreen from '../FirstScreen/FirstScreen';
import DownloadScreen from '../DownloadScreen/DownloadScreen';
import Footer from '../Footer/Footer';

import * as Api from '../../utils/Api';

function App() {
  const [downloadLink, setDownloadLink] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    // Получение ссылки на скачивание из файла конфигурации
    fetch(`${window.location.origin}/config.json`)
      .then(response => response.json())
      .then(data => {
        setDownloadLink(data.download_link);
      })
      .catch(error => {
        console.error('Error fetching config:', error);
      });
  }, []);

  const handleDownload = () => {
    if (downloadLink) {
      const downloadElement = document.createElement('a');
      downloadElement.href = downloadLink;
      downloadElement.download = 'downloaded_file';
      downloadElement.rel = 'noreferrer';
      downloadElement.click();
    }
  };

  const handleCheck = (license_key) => {
    Api.checkLicense(license_key)
    .then((data) => {
      console.log(data)
      if(data.is_active === true) {
        handleDownload();
      }
        setErrorMessage(data.error_message_rus);
      })
      return
  };

  return (
    <div className="page">
      <Header />
      <FirstScreen />
      <DownloadScreen
        onCheck={handleCheck}
        errorMessage={errorMessage}
      />
      <Footer />
    </div>
  );
}

export default App;
