// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    max-width: 100%;
    /* height: 47px; */
    box-sizing: border-box;
    background-color: #F9FAFB;
    border-top: 1px solid #DB2777;
}

.footer__section {
    margin: 12px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer__logo-link {
    height: 30px;
}

.footer__logo {
    height: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;IAClB,sBAAsB;IACtB,yBAAyB;IACzB,6BAA6B;AACjC;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".footer {\n    max-width: 100%;\n    /* height: 47px; */\n    box-sizing: border-box;\n    background-color: #F9FAFB;\n    border-top: 1px solid #DB2777;\n}\n\n.footer__section {\n    margin: 12px 24px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.footer__logo-link {\n    height: 30px;\n}\n\n.footer__logo {\n    height: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
