import './Footer.css';
import logoFooter from '../../images/logo-footer.svg';
import LogoGroup from '../LogoGroup/LogoGroup';

function Footer() {
    return(
        <footer className='footer'>
            <div className='footer__section'>
                <a className='footer__logo-link' href='https://mediaclass.digis.ru/' target='_blanc'>
                    <img className='footer__logo' src={logoFooter} alt='Логотип' />
                </a>
                <LogoGroup />
            </div>
        </footer>
    )
}

export default Footer;