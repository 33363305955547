// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.download-screen__input {
    display: inline-flex;
    flex-wrap: nowrap;
    padding: 12px 5px;
    width: 167px;
    min-width: 167px;
    max-width: 530px;
    border: none;
    border-bottom: 1px solid #6B7280;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #111827;
    background-color: #F9FAFB;
}

.download-screen__input:focus {
    outline: none;
    border-bottom: 2px solid #3B82F6;
    width: 180px;
    min-width: 180px;
    transition: all 0.5s ease;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
     box-shadow: 0 0 0 30px #F9FAFB inset !important;
}`, "",{"version":3,"sources":["webpack://./src/components/InputWithMask/InputWithMask.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,iBAAiB;IACjB,iBAAiB;IACjB,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;IAChB,YAAY;IACZ,gCAAgC;IAChC,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,gCAAgC;IAChC,YAAY;IACZ,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;;;;KAIK,+CAA+C;AACpD","sourcesContent":[".download-screen__input {\n    display: inline-flex;\n    flex-wrap: nowrap;\n    padding: 12px 5px;\n    width: 167px;\n    min-width: 167px;\n    max-width: 530px;\n    border: none;\n    border-bottom: 1px solid #6B7280;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 24px;\n    text-align: center;\n    color: #111827;\n    background-color: #F9FAFB;\n}\n\n.download-screen__input:focus {\n    outline: none;\n    border-bottom: 2px solid #3B82F6;\n    width: 180px;\n    min-width: 180px;\n    transition: all 0.5s ease;\n}\n\ninput:-webkit-autofill,\ninput:-webkit-autofill:hover,\ninput:-webkit-autofill:focus,\ninput:-webkit-autofill:active  {\n     box-shadow: 0 0 0 30px #F9FAFB inset !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
