import React, { useRef, useState } from "react";
import './InputWithMask.css';

function InputWithMask(props) {
    const inputRef = useRef(null);
    const [selection, setSelection] = useState(null);

    const handleKeyDown = (evt) => {
        // Check if Ctrl+A was pressed
        if ((evt.metaKey || evt.ctrlKey ) && evt.key === 'a') {
            evt.preventDefault();
            inputRef.current.setSelectionRange(0, inputRef.current.value.length);
            setSelection({ start: 0, end: inputRef.current.value.length });
        }
    };

    const handleKeyUp = (evt) => {
        if (evt.key === 'Control' || evt.key === 'Meta') {
            inputRef.current.setSelectionRange(selection.start, selection.end);
        }
    }

    const handlePaste = (evt) => {
        const pastedText = evt.clipboardData.getData('text');
        const currentPosition = inputRef.current.selectionStart;
        const newValue = props.value.substring(0, currentPosition) + pastedText + props.value.substring(inputRef.current.selectionEnd);

        props.onChange({
            target: {
                name: props.name,
                value: newValue
            }
        });

        const endPosition = currentPosition + pastedText.length;
        inputRef.current.setSelectionRange(endPosition, endPosition);
        setSelection({ start: currentPosition, end: endPosition });
        evt.preventDefault();
    }

    return(
        <>
            <input
                className='download-screen__input'
                type='text'
                name='license_key'
                id='licenseKey'
                placeholder='****-****-****-****'
                value={props.value}
                maxLength={27}
                minLength={27}
                required
                onChange={props.onChange}
                onBlur={props.onBlur}
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
                onPaste={handlePaste}
                ref={inputRef}
            />
        </>
    )
}

export default InputWithMask