import './LogoGroup.css';
import React from 'react';
import logoGroup from '../../images/logo-group.png';

function LogoGroup () {
    return(
        <a className='logo-link' href="https://palatine.ru/" target='_blanc'>
            <img className='logo-group' src={logoGroup} alt="Логотип группы"/>
        </a>
    )
}

export default LogoGroup
