// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.download-screen__instructions-item {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 6px;
}`, "",{"version":3,"sources":["webpack://./src/components/ItemInstruction/ItemInstruction.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".download-screen__instructions-item {\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 24px;\n    margin-bottom: 6px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
