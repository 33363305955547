import { useEffect, useState } from "react";

export function useInputValidation(initialInputValue, validations) {
  const [inputValue, setInputValues] = useState(initialInputValue);
  const [isDirty, setIsDirty] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [minLengthError, setMinLengthError] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isBtnClick, setIsBtnClick] = useState(false);

    useEffect(() => {
        if (isEmpty || minLengthError) {
            setIsValid(false);
        } else {
            setIsValid(true);
        }
    }, [isEmpty, minLengthError])

    useEffect(() => {
        if (!isBtnClick) {
            return;
        }

        let message = '';
        for (const validation in validations) {
            // eslint-disable-next-line default-case
            switch (validation) {
                case "minLength":
                    if (inputValue && inputValue.length < validations[validation]) {
                        message = `Длина поля не должна быть меньше ${validations[validation]} символов`;
                        setMinLengthError(true);
                    } else {
                        setMinLengthError(false);
                    }
                    break;
                case "isEmpty":
                    if (!inputValue && inputValue.length === 0) {
                        setIsEmpty(true);
                        message = `Для скачивания дистрибутива необходимо ввести серийный ключ`;
                    } else {
                        setIsEmpty(false);
                    }
                    break;
            }
        }
        setValidationMessage(message);
    }, [isBtnClick, inputValue])

    const checkAutoresize = () => {
        let inputKey = document.getElementById('licenseKey');
        // Создаем новый экземпляр MutationObserver с функцией обратного вызова
        const observer = new MutationObserver((mutationList, observer) => {
            autoresize();
        });
        //Настройка MutationObserver для отслеживания изменений атрибудет "value" элемента input
        observer.observe(inputKey, { auttributes: true, attributeFilter: ['value'] });

        function autoresize() {
            setTimeout(() =>{
                inputKey.style.width = 'auto';
                let size = inputKey.scrollWidth + 1;
                inputKey.style.width = size + 'px';
                inputKey.style.transition = "none";
            }, 0);
        }
    }

    const checkAutoDashInput = () => {
        let inputKey = document.getElementById('licenseKey');
        inputKey.addEventListener('keyup', autoDashInput);

        function autoDashInput(e) {
            var inputValue = inputKey.value.toUpperCase();
            // Получаем текущую позицию курсора
            var cursorPosition = inputKey.selectionStart;
            // Убираем все дефисы
            var cleanedValue = inputValue.replace(/-/g, '');
            // Добавляем дефисы после каждых 6 символов
            var modifiedValue = cleanedValue.replace(/(.{6})/g, '$1-');
            // Удаляем дефис в конце строки, если он есть
            modifiedValue = modifiedValue.replace(/-$/, '');
            // Разница между длиной старого и нового значения
            var lengthDiff = modifiedValue.length - inputValue.length;
            // Обновляем значение поля ввода
            inputKey.value = modifiedValue;
            // Корректируем позицию курсора при добавлении дефисов
            inputKey.setSelectionRange(cursorPosition + lengthDiff, cursorPosition + lengthDiff);
        }
    };

    function handleInputChange(evt) {
        setInputValues(evt.target.value);
        checkAutoresize();
        checkAutoDashInput();
    }

    function handleInputBlur(evt) {
        if (evt.target.value.trim() !== '') {
            setIsDirty(true);
        }
    }

    function handleOnClick() {
        setIsBtnClick(true);
    }

    return {
        inputValue,
        handleInputChange,
        handleInputBlur,
        handleOnClick,
        isDirty,
        isEmpty,
        minLengthError,
        validationMessage,
        isValid,
        isBtnClick,
    }
}