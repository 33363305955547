// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    width: 100%;
    position: fixed;
    top: 0;
    background-color: #F9FAFB;
    box-sizing: border-box;
    z-index: 100;
    transition: border-bottom 0.3s ease;
}

.header.scrolled {
    border-bottom: 1px solid #009AD8;
}

.header__logo-link {
    height: 42px;
}

.header__section {
    margin: 12px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
}

.header__logo {
    height: 42px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;IACf,MAAM;IACN,yBAAyB;IACzB,sBAAsB;IACtB,YAAY;IACZ,mCAAmC;AACvC;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,sBAAsB;AAC1B;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".header {\n    width: 100%;\n    position: fixed;\n    top: 0;\n    background-color: #F9FAFB;\n    box-sizing: border-box;\n    z-index: 100;\n    transition: border-bottom 0.3s ease;\n}\n\n.header.scrolled {\n    border-bottom: 1px solid #009AD8;\n}\n\n.header__logo-link {\n    height: 42px;\n}\n\n.header__section {\n    margin: 12px 24px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    box-sizing: border-box;\n}\n\n.header__logo {\n    height: 42px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
