import { BASE_URL } from '../utils/consts';

function getResponse(res) {
    if (!res.ok) {
      return Promise.reject(`Ошибка: ${res.status}`);
    }
    return res.json();
}

export function checkLicense(license_key) {
  const API_LOGIN = 'license_admin';
  const API_PASSWORD = 'fkDYXvbK2jSOC04kH9wSBbYE7JNHFOW';

  const credentials = btoa(`${API_LOGIN}:${API_PASSWORD}`);

  return fetch(`${BASE_URL}/api/license/check`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Basic ${credentials}`
    },
    body: JSON.stringify({license_key})
  })
  .then(getResponse)
  .then((data) => {
    return data
  })
}
