import React, { useEffect, useState } from 'react';
import './Header.css';
import logoHeader from '../../images/logo-header.svg';
import LogoGroup from '../LogoGroup/LogoGroup';

function Header() {
    const [isSrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setIsScrolled(scrollPosition > 100);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    return(
        <header className={isSrolled ? 'header scrolled' : 'header'}>
            <div className='header__section'>
                <a className='header__logo-link' href='https://mediaclass.digis.ru/' target='_blanc'>
                    <img className='header__logo' src={logoHeader} alt="Логотип" />
                </a>
                <LogoGroup />
            </div>
        </header>
    )
}

export default Header
