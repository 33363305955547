import React, { useState } from "react";
import './DownloadScreen.css';
import { items } from '../../utils/instructionsDownload';
import ItemInstruction from "../ItemInstruction/ItemInstruction";
import InputWithMask from "../InputWithMask/InputWithMask";
import { useInputValidation } from "../../utils/validation";

function DownloadScreen(props) {
    const [isVisibleInstruction, setIsVisibleInstruction] = useState(false);
    const [isBtnActive, setIsBtnActive] = useState(false);

    const licKey = useInputValidation('', { isEmpty: true, minLength: 27 , isBtnClick: false});

    const validationSpan = (
        <>
            {(licKey.isDirty && licKey.validationMessage) && <span className="download-screen__input-error">{licKey.validationMessage}</span>}
        </>
    )

    const errorSpan = (
        <>
            <span className="download-screen__input-error">{props.errorMessage}</span>
        </>
    )

    const versionSpan = props.errorMessage ? errorSpan : validationSpan;

    const changeVesibleInstruction = () => {
        setIsVisibleInstruction(!isVisibleInstruction);
        setIsBtnActive(!isBtnActive);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!licKey.inputValue) {
            return
        } else if (licKey.inputValue && licKey.isValid) {
        props.onCheck(licKey.inputValue);
        }
    }

    const resetButtonState = () => {
        setIsBtnActive(false);
    }

    return(
        <section className="download-screen" id="download-screen">
            <div className={isVisibleInstruction ? "download-screen__container" : "download-screen__container download-screen__container-center"}>
                <form className='download-screen__form' name='download-screen__form' onSubmit={handleSubmit} noValidate>
                    <p className='download-screen__title'>Введите серийный ключ</p>
                    <label className="download-screen__inpur-field">
                        <InputWithMask
                            value={licKey.inputValue}
                            onChange={licKey.handleInputChange}
                            onBlur={licKey.handleInputBlur}
                        />
                        {versionSpan}
                    </label>
                        <button
                            className='download-screen__btn'
                            type='submit'
                            onClick={() => {
                                licKey.handleOnClick();
                                resetButtonState(); // Вызов функции сброса состояния кнопки
                            }}
                            >
                            Скачать дистрибутив
                        </button>
                </form>
                <div className='download-screen__instructions'>
                    <div className='download-screen__instructions-box' onClick={changeVesibleInstruction}>
                        <h2 className='download-screen__instructions-title'>Инструкция по установке и активации программы</h2>
                        <button
                            className={!isBtnActive ? 'download-screen__instructions-btn' : 'download-screen__instructions-btn download-screen__instructions-btn-active'}
                            type="button"
                            id="btn-instruction"
                            >
                        </button>
                    </div>
                    {isVisibleInstruction && <ol className='download-screen__instructions-list'>
                            {items.map(item => (
                                <ItemInstruction
                                    key={item.id}
                                    item={item}
                                    description={item.description}
                                />
                            ))}
                    </ol>}
                </div>
            </div>
        </section>
    )
}

export default DownloadScreen;